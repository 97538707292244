

var bLog=true;
var bDebugIos=false;
var bPerf=false;
var startTime = performance.now();
window.uiLog=function(...args)
{
    if(bLog)
    {
        if(bPerf)
        {
            const elapsed = Math.round(performance.now() - startTime);
            console.log(`[${elapsed}ms]`, ...args);
        }
        else
        {
            console.log(...args);
        }
    }
};
if(bDebugIos)
{
    window.isIOS = true;
    window.isChrome=false;
    window.isSafari=true;
}
else
{
    window.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    window.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    window.isAndroid = /Android/i.test(navigator.userAgent);
    window.isChrome = /CriOS/i.test(navigator.userAgent);
}

window.pwa = function()
{
    if(! window.appPWA)
    {
        //console.log("pwa");
        return false;
    }
    //console.log("pwanot");
    return window.appPWA.isPwaEnvironment();
}
window.pwaOnline = function()
{
    if(pwa())
    {
        if(appPWA.isOnline())
        {
            return true;
        }
    }
    return false;
}   
window.apiCall = function(url, method, data, callSuccess, callError)
{
    if(navigator.onLine)
    {
                // Function to process the response
        const processResponse = function(response) {
            if (response.ok) {
                return response.json().then(data => {
                    callSuccess(data);
                });
            } else {
                    return response.json().then(data => {
                        throw new Error(JSON.stringify({
                            message: data.message || 'API Error',
                            status: response.status,
                            url: response.url,
                            data: data,
                            timestamp: new Date().toISOString()
                        }));
                    });
            }
        };
        // Function to handle errors
        const handleError = function(error) {
            try {
                console.error(JSON.parse(error.message));
                callError(JSON.parse(error.message));
            } catch (parseError) {
                console.error('Failed to parse error message:', error.message);
            }

        };
         // Prepare headers
         const headers = new Headers({
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        });
        fetch(url,{
            method: method,
            headers: headers,
            body: method !== 'GET' ? JSON.stringify(data) : undefined
        })
        .then(processResponse)
        .catch(handleError);
    }
};
window.apiCallBinary = function(url, method, data) {
    return new Promise((resolve, reject) => {
        if (!navigator.onLine) {
            reject(new Error('No internet connection'));
            return;
        }

        const headers = new Headers({
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        });

        fetch(url, {
            method: method,
            headers: headers,
            body: method !== 'GET' ? JSON.stringify(data) : undefined
        })
        .then(response => {
            if (response.ok) {
                return response.arrayBuffer();
            }
            return response.json().then(data => {
                throw new Error(JSON.stringify({
                    message: data.message || 'API Error',
                    status: response.status,
                    url: response.url,
                    data: data,
                    timestamp: new Date().toISOString()
                }));
            });
        })
        .then(resolve)
        .catch(error => {
            try {
                console.error(JSON.parse(error.message));
                reject(JSON.parse(error.message));
            } catch (parseError) {
                console.error('Failed to parse error message:', error.message);
                reject(error);
            }
        });
    });
}
window.logout = async function(event)
{
    event.preventDefault();
    if(pwa())
    {
        console.log("pwa logout");
        await appPWA.refreshCsrfToken();
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then(function(registration) {
                registration.active.postMessage({ action: 'logout' });
            });
        }
        await window.appPWA.handleLogout();
//        event.target.closest('form').submit();
        window.location.href = "/logout";
    }
    else
    {
        console.log("non pwa logout");
        
        //event.target.closest('form').submit();
        window.location.href ="/logout";
    }
};
window.getCookie = function(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    let ret=null;
    if (parts.length === 2) ret= parts.pop().split(';').shift();
    return ret;
};

window.setCookie = function(name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/;SameSite=Strict";
};
